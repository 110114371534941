import React from 'react';
import { get, getImageUrl } from 'utils/magnolia';
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax

mapboxgl.accessToken = 'pk.eyJ1IjoibmVpcmRhODIiLCJhIjoiY2pxd2d1aHp5MHV4MzQ5bXF5cjJqemtzbiJ9.dw3X1wXK-ydEtNhfCPasSQ';

class ContactDetail extends React.Component {
  state = {};
  constructor(props) {
    super(props);
    this.state = {
      lng: -70.9,
      lat: 42.35,
      zoom: 9,
    };
    this.mapContainer = React.createRef();
  }
  async componentDidMount() {
    const { contactId } = this.props.match.params;
    const contact = await get('/.rest/delivery/contact/' + contactId, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (contact !== {}) {
      this.setState({ contact: contact });
    }

    const lng = contact.longitude;
    const lat = contact.latitude;
    const street = contact.street;
    const city = contact.city;
    const postalCode = contact.postalCode;
    const phone = contact.mobile;
    const zoom = 9;
    //const { lng, lat, zoom } = this.state;
    const map = new mapboxgl.Map({
      container: this.mapContainer.current,
      style: 'mapbox://styles/mapbox/streets-v11',
      center: [lng, lat],
      zoom: zoom,
    });
    function flyToStore(currentFeature) {
      map.flyTo({
        center: currentFeature.geometry.coordinates,
        zoom: 15,
      });
    }

    function createPopUp(currentFeature) {
      const popUps = document.getElementsByClassName('mapboxgl-popup');
      /** Check if there is already a popup on the map and if so, remove it */
      if (popUps[0]) popUps[0].remove();
      new mapboxgl.Popup({ closeOnClick: false })
        .setLngLat(currentFeature.geometry.coordinates)
        .setHTML(
          `<h3>Store Location</h3><h4>${currentFeature.properties.address}</h4><h4>${currentFeature.properties.city}</h4><h4>${currentFeature.properties.postalCode}</h4><h4>${currentFeature.properties.phone}</h4>`
        )
        .addTo(map);
    }

    function addMarkers() {
      /* For each feature in the GeoJSON object above: */
      for (const marker of stores.features) {
        /* Create a div element for the marker. */
        const el = document.createElement('div');
        /* Assign a unique `id` to the marker. */
        el.id = `marker-${marker.properties.id}`;
        /* Assign the `marker` class to each marker for styling. */
        el.className = 'marker';

        /**
         * Create a marker using the div element
         * defined above and add it to the map.
         **/
        new mapboxgl.Marker(el, { offset: [0, -23] }).setLngLat(marker.geometry.coordinates).addTo(map);
        el.addEventListener('click', (e) => {
          /* Fly to the point */
          flyToStore(marker);
          /* Close all other popups and display popup for clicked store */
          createPopUp(marker);
          /* Highlight listing in sidebar */
          const activeItem = document.getElementsByClassName('active');
          e.stopPropagation();
          if (activeItem[0]) {
            activeItem[0].classList.remove('active');
          }
          const listing = document.getElementById(`listing-${marker.properties.id}`);
          listing.classList.add('active');
        });
      }
    }
    // store location
    const stores = {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: [lng, lat],
          },

          properties: {
            phone: phone,
            address: street,
            city: city,
            country: street,
            postalCode: postalCode,
            state: 'D.C.',
          },
        },
      ],
    };
    map.on('load', () => {
      /* Add the data to your map as a layer */
      map.addSource('places', {
        type: 'geojson',
        data: stores,
      });
      addMarkers();
    });
    map.on('move', () => {
      this.setState({
        lng: map.getCenter().lng.toFixed(4),
        lat: map.getCenter().lat.toFixed(4),
        zoom: map.getZoom().toFixed(2),
      });
    });
    map.on('click', (event) => {
      /* Determine if a feature in the "locations" layer exists at that point. */
      const features = map.queryRenderedFeatures(event.point, {
        layers: ['locations'],
      });

      /* If it does not exist, return */
      if (!features.length) return;

      const clickedPoint = features[0];

      /* Fly to the point */
      flyToStore(clickedPoint);

      /* Close all other popups and display popup for clicked store */
      createPopUp(clickedPoint);
    });
  }

  render() {
    const { contact = {} } = this.state;
    const { name, email, title, mobile, description, photo } = contact;

    return (
      <div className='ContactPage'>
        <div className='wrapper'>
          <div className='col col--1-3 text-center'>
            <img className='ContactPage__img' src={getImageUrl(photo)} alt={name} />
          </div>
          <div className='col col--2-3'>
            <div className='ContactComponent__name'>{name}</div>
            <div className='ContactComponent__title'>{title}</div>
            <div className='ContactPage__contact'>
              <div>{mobile}</div>
              <div>{email}</div>
              <a className='ContactPage__action vertical-center Btn Btn--2' href={'mailto:' + email}>
                Contact
              </a>
            </div>
            <p className='ContactPage__description ' dangerouslySetInnerHTML={{ __html: description }}></p>
          </div>
          <div ref={this.mapContainer} className='ContactPage__map' />
        </div>
      </div>
    );
  }
}

export default ContactDetail;
