import React from 'react';

import { EditableComponent } from '@magnolia/react-editor';
import { get } from 'utils/magnolia';

class CustomerJourney extends React.Component {
  state = {};

  async componentDidMount() {
    const res = await get('/customer/journey/' + this.props.tag, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(window.MT.getTrackingObject()),
    });

    this.setState({ main: res.main });
  }

  render() {
    const { main } = this.state;

    return (
      <div>
        {main && (
          <div>
            {main['@nodes'].map((node) => (
              <EditableComponent key={main[node]['@id']} content={main[node]} />
            ))}
          </div>
        )}
      </div>
    );
  }
}

export default CustomerJourney;
