import React, { useState, useEffect } from 'react';
import { EditableArea } from '@magnolia/react-editor';
import { Link } from 'react-router-dom';
import url from 'utils/url';
import { get, getImageUrl } from 'utils/magnolia';

function EventsPage(props) {
  const { main, metadata, maxElements, sorting, tagsFilterMode } = props;
  const [events, setEvents] = useState([]);

  const pipe = '%7C'; // Equals |

  useEffect(() => {
    async function getEvents() {
      const tags = metadata['mgnl:tags'];

      let tagsFilter = tags[0] ? 'mgnl:tags=' + tags[0] : null;

      if (tags.length > 1) {
        // OR MODE
        if (tagsFilterMode && tagsFilterMode === 'or') {
          tagsFilter = 'mgnl:tags=' + tags[0];

          for (let index = 1; index < tags.length; index++) {
            tagsFilter = tagsFilter + pipe + tags[index];
          }
        } else {
          // AND MODE
          for (let index = 1; index < tags.length; index++) {
            tagsFilter = tagsFilter + '&mgnl:tags=' + tags[index];
          }
        }
      }

      const orderFilter = sorting ? 'orderBy=startDate%20' + sorting : null;
      const maxElementFilter = maxElements ? 'limit=' + maxElements : null;

      let filterString = '';

      if (tagsFilter != null) {
        if (!filterString) filterString = '?';
        filterString += tagsFilter;
      }

      if (orderFilter) {
        if (!filterString) filterString = '?';
        else filterString += '&';
        filterString += orderFilter;
      }

      if (maxElementFilter) {
        if (!filterString) filterString = '?';
        else filterString += '&';
        filterString += maxElementFilter;
      }

      const newEvents = await get('/.rest/events' + filterString);

      setEvents(newEvents.results);
    }

    getEvents();
  }, [metadata, maxElements, sorting, tagsFilterMode]);

  function renderEvent(event) {
    const { image, name, abstract, startDate } = event;
    const style = {};
    let croppedAbstract = '';

    if (abstract) croppedAbstract = abstract.length > 100 ? abstract.substring(0, 99) + '...' : abstract;
    if (image) style.backgroundImage = `url(${getImageUrl(image)})`;

    return (
      <Link key={event['@id']} to={url.getPageLink('/events' + event['@path'])} className='Stories__story'>
        <div className='Stories__storyMeta'>
          <div className='Stories__storyAuthor'></div>
          <div className='Stories__storyDate'>
            {startDate ? new Intl.DateTimeFormat('en-GB').format(new Date(startDate)) : ''}
          </div>
        </div>
        <div className='Stories__storyImage cover' style={style} />
        <div className='Stories__storyTag'>EVENT</div>
        <div className='Stories__storyTitle'>{name}</div>
        <div className='Stories__storyLead'>{croppedAbstract}</div>
      </Link>
    );
  }

  return (
    <div className='Events'>
      <EditableArea content={main} />
      <div className='wrapper text-center'>
        <div className='Stories-wrapper'>
          <div className='Stories'>{events.map((event) => renderEvent(event))}</div>
        </div>
      </div>
    </div>
  );
}

export default EventsPage;
