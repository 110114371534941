import React from 'react';

import { getImageUrl } from 'utils/magnolia';

function FileDownload(props) {
  const { title, image } = props;
  const filesToRender = [];

  for (let i = 0; i < image.length; i++) {
    if (image[i]) {
      filesToRender.push(
        <a
          key={image[i]['@id']}
          className='FileDownload--Link'
          href={getImageUrl(image[i])}
          target='_blank'
          rel='noopener noreferrer'
        >
          <span>{image[i]?.metadata?.fileName}</span>
        </a>
      );
    }
  }

  return (
    <div className='FileDownload--Container'>
      {title && <h3 className='FileDownload--Titel'>{title}</h3>}
      {filesToRender.length > 0 && <div className='FileDownload--wrapper'>{filesToRender}</div>}
    </div>
  );
}

export default FileDownload;
