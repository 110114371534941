import React from 'react';
import { get, getImageUrl } from 'utils/magnolia';

class Story extends React.Component {
  state = {};

  async componentDidMount() {
    const storyId = this.props.story;

    const stories = await get('/.rest/story/?@jcr:uuid=' + storyId);
    const story = stories?.results[0];

    this.setState({ story });
  }

  renderStory = (story) => {
    const { title, lead, imagesource, author, created = '' } = story;
    const style = {};

    if (imagesource) style.backgroundImage = `url(${getImageUrl(imagesource)})`;

    return (
      <div className='Story'>
        <div className='wrapper'>
          <div className='Story__visual cover' style={style}>
            <div className='Banner__inner wrapper text-center'>
              <div className='Banner__title '>{title}</div>
              <div className='Banner__subtitle'>{lead}</div>
            </div>
          </div>
          <div className='Story__info'>
            <div className='Story__author'>{author}</div>
            <div className='Story__created'>
              {created ? new Intl.DateTimeFormat('en-GB').format(new Date(created)) : ''}
            </div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    const { story } = this.state;

    return story ? this.renderStory(story) : null;
  }
}

export default Story;
