import React from 'react';
import { EditableArea } from '@magnolia/react-editor';
import MetaInfo from 'components/MetaInfo';

function Basic(props) {
  const { main } = props;

  return (
    <div className='Basic'>
      <MetaInfo props={props} />
      {main && <EditableArea content={main} />}
    </div>
  );
}

export default Basic;
