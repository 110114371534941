import React from 'react';
import { Link } from 'react-router-dom';

import url from 'utils/url';
import BackgroundImage from 'utils/BackgroundImage';

function Card(props) {
  const { className, title, lead, image, link = '', linkManual, focalMethod } = props;
  let fullClassName = 'Card';

  if (className) fullClassName += ' ' + className;

  const linkTo = linkManual ? linkManual : url.getPageLink(link);

  return (
    <Link to={linkTo} className={fullClassName}>
      <BackgroundImage className='Card__image' method={focalMethod} image={image} title={linkTo} data-mt />
      <div className='Card__title'>{title}</div>
      {lead && <p className='Card__lead'>{lead}</p>}
    </Link>
  );
}

export default Card;
