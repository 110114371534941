import React, { useState, useEffect } from 'react';
import { get } from 'utils/magnolia';
import Settings from 'utils/Settings';

function clone(obj) {
  return JSON.parse(JSON.stringify(obj));
}

function sortByOrderIndex(a, b) {
  if (a.orderIndex < b.orderIndex) {
    return -1;
  }

  if (a.orderIndex > b.orderIndex) {
    return 1;
  }

  return 0;
}

function Poll(props) {
  const [poll, setPoll] = useState({});
  const [values, setValues] = useState({});

  useEffect(() => {
    async function getPoll() {
      const poll = await get('/.rest/forms/v1/forms/' + props.pollId, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      setPoll(poll);
    }

    getPoll();
  }, [props.pollId]);

  async function handleSubmit(e) {
    e.preventDefault();

    let body = [];

    Object.keys(values).forEach((key) => {
      const value = values[key];

      if (Array.isArray(value)) {
        value.forEach((item) => {
          body.push({
            questionId: key,
            value: item,
          });
        });
      } else {
        body.push({
          questionId: key,
          value,
        });
      }
    });

    await get('/.rest/forms/v1/forms/' + props.pollId, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    });

    setPoll(false);
    window.scrollTo(0, 0);
  }

  function handleChange(e) {
    const id = e.currentTarget.dataset.id;
    const value = e.currentTarget.value;

    setValues((prev) => {
      let newValues = clone(prev);

      newValues[id] = value;

      return newValues;
    });
  }

  function handleMultiChange(e) {
    const id = e.currentTarget.dataset.id;
    const value = e.currentTarget.value;
    const checked = e.currentTarget.checked;

    setValues((prev) => {
      let newValues = clone(prev);

      if (!newValues[id]) newValues[id] = [];

      if (checked) {
        newValues[id].push(value);
      } else {
        const index = newValues[id].indexOf(value);

        if (index > -1) newValues[id] = newValues[id].slice(0, index);
      }

      return newValues;
    });
  }

  function renderQuestion(questionObj) {
    const {
      id,
      title,
      question,
      questionType,
      answerOptions = [],
      rangeFrom,
      rangeFromLabel,
      rangeTo,
      rangeToLabel,
    } = questionObj;

    return (
      <div key={'Poll-question' + id} className='Poll__question'>
        <div className='Poll__questionHeader'>
          <div className='Poll__questionTitle'>{title}</div>
          <div className='Poll__questionQuestion'>{question}</div>

          {questionType === 'text' && (
            <input name={id} className='Poll__questionText' type='text' onChange={handleChange} data-id={id} />
          )}
          {questionType === 'single' && (
            <div className='Poll__questionRadio'>
              {answerOptions.sort(sortByOrderIndex).map((option) => {
                const { title, value } = option;
                const idEle = id + option.id;

                return (
                  <div key={idEle}>
                    <input id={idEle} type='radio' name={id} value={value} onChange={handleChange} data-id={id} />
                    <label htmlFor={idEle}>{title}</label>
                  </div>
                );
              })}
            </div>
          )}
          {questionType === 'multi' && (
            <div className='Poll__questionCheckbox'>
              {answerOptions.sort(sortByOrderIndex).map((option) => {
                const { title, value } = option;
                const idEle = id + option.id;

                return (
                  <div key={idEle}>
                    <input
                      id={idEle}
                      type='checkbox'
                      name={id}
                      value={value}
                      onChange={handleMultiChange}
                      data-id={id}
                    />
                    <label htmlFor={idEle}>{title}</label>
                  </div>
                );
              })}
            </div>
          )}
          {questionType === 'range' && (
            <div className='Poll__questionRange'>
              <input name={id} type='range' min={rangeFrom} max={rangeTo} onChange={handleChange} data-id={id} />
              <label>
                {rangeFromLabel && <div>{rangeFromLabel}</div>}
                {rangeToLabel && <div>{rangeToLabel}</div>}
              </label>
            </div>
          )}
        </div>
      </div>
    );
  }

  function renderSection(section) {
    const { id, pollId, title, description, questions = [] } = section;

    return (
      <div key={'Poll' + pollId + id} className='Poll__section'>
        <div className='Poll__sectionTitle'>{title}</div>
        <div className='Poll__sectionDescription'>{description}</div>
        {questions.sort(sortByOrderIndex).map((question) => renderQuestion(question))}
      </div>
    );
  }

  return (
    <div className='Poll wrapper'>
      {poll ? (
        <form className='Poll wrapper' onSubmit={handleSubmit}>
          <div className='Poll__header'>
            <div className='Poll__title'>{poll.title}</div>
            <div className='Poll__description'>{poll.description}</div>
          </div>
          {poll.sections?.sort(sortByOrderIndex).map((section) => renderSection(section))}
          <button>{Settings.getItem('translations').pollSubmit}</button>
        </form>
      ) : (
        <h2 className='text-center'>{Settings.getItem('translations').pollSubmitSuccessful}</h2>
      )}
    </div>
  );
}

export default Poll;
