import React from 'react';
import { get } from 'utils/magnolia';
import Settings from 'utils/Settings';
import { ReactComponent as Star } from 'img/star-blue.svg';
import { ReactComponent as Like } from 'img/like.svg';
import { ReactComponent as Dislike } from 'img/dislike.svg';
import { ReactComponent as ReportAbuse } from 'img/forbidden.svg';

class Commenting extends React.Component {
  state = { comments: [], key: this.props.props };

  async componentDidMount() {
    this.getComments();
  }

  handleInputChange = (e) => {
    const target = e.target;
    const value = target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  };

  async getComments() {
    const comments = await get('/.rest/commenting/v1/comments/stories/' + this.state.key, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    this.setState({ comments: comments });
  }

  submitForm = async () => {
    var user = this.state.user;
    var comment = this.state.comment;
    var email = this.state.email;
    var id = this.state.key;
    var data = {
      mgnlWorkspace: 'stories',
      mgnlId: id,
      text: comment,
      authorChoice: false,
      abuseReport: false,
      numLikes: 0,
      numUnlikes: 0,
      rating: 0,
      userName: user,
      userEmail: email,
    };
    const baseUrl = Settings.getItem('baseUrl');
    await fetch(baseUrl + '/.rest/commenting/v1/comment/', {
      method: 'POST',
      body: JSON.stringify(data),
      headers: { 'Content-Type': 'application/json' },
    }).then((response) => {
      this.getComments();
    });
  };

  async like(id) {
    const baseUrl = Settings.getItem('baseUrl');
    const like = await fetch(baseUrl + '/.rest/commenting/v1/like/' + id, {
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
    });
    if (like) {
      this.getComments();
    }
  }

  async dislike(id) {
    const baseUrl = Settings.getItem('baseUrl');
    const like = await fetch(baseUrl + '/.rest/commenting/v1/dislike/' + id, {
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
    });
    if (like) {
      this.getComments();
    }
  }

  async reportAbuse(id) {
    const baseUrl = Settings.getItem('baseUrl');
    const like = await fetch(baseUrl + '/.rest/commenting/v1/report/' + id, {
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
    });
    if (like) {
      this.getComments();
    }
  }
  timestampToDate(time) {
    var date = new Date(time);
    var now = new Date();
    var timeDiff = Math.abs(now - date);

    if (timeDiff / (1000 * 10) < 1) {
      return 'just now';
    } else if (timeDiff / (1000 * 60) < 1) {
      return Math.round(timeDiff / 1000) + ' seconds ago';
    } else if (timeDiff / (1000 * 60 * 60) < 1) {
      return Math.round(timeDiff / (1000 * 60)) + ' minutes ago';
    } else if (timeDiff / (1000 * 60 * 60 * 24) < 1) {
      return Math.round(timeDiff / (1000 * 60 * 60)) + ' hours ago';
    } else if (timeDiff / (1000 * 60 * 60 * 24 * 1) < 1) {
      return 'yesterday at ' + ('0' + date.getHours()).slice(-2) + ':' + ('0' + date.getMinutes()).slice(-2);
    } else {
      return (
        date.getDate() +
        '.' +
        (date.getMonth() + 1) +
        '. at ' +
        ('0' + date.getHours()).slice(-2) +
        ':' +
        ('0' + date.getMinutes()).slice(-2)
      );
    }
  }

  renderListComments() {
    const { comments = [] } = this.state;

    if (comments === [] || comments === false) return <></>;

    return comments.map((k) => {
      return k.abuseReport === false ? (
        <div className='Comment'>
          <div>
            <div className='comment_header'>
              {k.authorChoice === true ? (
                <span className='comment_icon'>
                  <Star className='Btn__actions' />
                </span>
              ) : null}
              <b>{k.userName}</b> &nbsp; <span className='label label-primary'>{this.timestampToDate(k.created)}</span>
            </div>
            <div className='comment_body'>{k.text}</div>

            <div className='comment_actions'>
              <b>{k.numLikes}</b>
              <Like className='Btn__actions' onClick={() => this.like(k.id)} />

              <b>{k.numUnlikes}</b>
              <Dislike className='Btn__actions' onClick={() => this.dislike(k.id)} />

              <ReportAbuse className='Btn__actions' onClick={() => this.reportAbuse(k.id)} />
            </div>
          </div>
        </div>
      ) : null;
    });
  }

  render() {
    const { user, comment, email } = this.state;

    return (
      <div className='Comments__info-wrapper'>
        <div className='Comment'>{this.renderListComments()}</div>
        <form className='Comment_form' id='commentForm'>
          <div className='Comments__info'>
            <div className='title title--2'>Your name:</div>
            <input
              className='Comments__info'
              type='text'
              name='user'
              onChange={this.handleInputChange}
              value={user}
              required
            />
          </div>
          <div className='Comments__info'>
            <div className='title title--2'>Your email:</div>
            <input
              name='email'
              type='email'
              onChange={this.handleInputChange}
              value={email}
              className='Comments__info'
              required
            />
          </div>
          <div className='Comments__info'>
            <div className='title title--2'>Your comment:</div>
            <textarea
              className='Comments__textarea'
              rows='3'
              name='comment'
              onChange={this.handleInputChange}
              value={comment}
              required
            ></textarea>
          </div>
          <div className='Comments__info'>
            <input type='button' onClick={() => this.submitForm()} className='Btn Btn--2' value='Add comment'></input>
          </div>
        </form>
      </div>
    );
  }
}

export default Commenting;
