import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { EditableArea } from '@magnolia/react-editor';
import Product from 'components/Product';
import Settings from 'utils/Settings';
import url from 'utils/url';

function Search(props) {
  const [items, setItems] = useState([]);
  const { pathname, search } = useLocation();
  const { main } = props;

  useEffect(() => {
    async function getSearch() {
      const query = url.getSearchParam('query');

      if (query) {
      } else {
        setItems([]);
      }
    }

    if (pathname.indexOf('/search') > -1) getSearch();
  }, [pathname, search]);

  return (
    <div className='wrapper'>
      <EditableArea content={main} />
      <div className='Search__items'>
        {items.map((item) => {
          return (
            <div key={'Search' + item.id} className='col col--1-4'>
              <Product productId={item.id} />
            </div>
          );
        })}
        {items.length === 0 && Settings.getItem('translations').noResults}
      </div>
    </div>
  );
}

export default Search;
