import React from 'react';
import { Link } from 'react-router-dom';

import { GetProductById } from 'utils/commerce';
import url from 'utils/url';
import { getImageUrl } from 'utils/magnolia';

import 'components/Hotspot/index.css';

class Hotspot extends React.Component {
  state = {};

  getInfo = async (fullProductId) => {
    const product = await GetProductById(fullProductId);
    this.setState({ info: product, fullProductId });
  };

  render() {
    const { image } = this.props;
    const { info, fullProductId } = this.state;

    return (
      <div className='HotspotWrapper'>
        <div className='Hotspot'>
          <img className='Hotspot__image' src={getImageUrl(image)} alt='' />
          {info && (
            <div className='Hotspot__info'>
              <Link className='Hotspot__infoLink' to={url.getProductLink(fullProductId)}>
                {info.name}
              </Link>
              {info.description}
            </div>
          )}
          {image.focal &&
            image.focal.hotspots &&
            image.focal.hotspots.map((spot, i) => {
              return (
                <div
                  key={image['@id'] + '-spot-' + i}
                  className='Hotspot__spot'
                  style={{ top: `calc(${spot.top}% - 20px)`, left: `calc(${spot.left}% - 20px)` }}
                  data-active={info && info.id === spot.productId.split('/').pop()}
                  onClick={() => this.getInfo(spot.productId)}
                />
              );
            })}
        </div>
      </div>
    );
  }
}

export default Hotspot;
