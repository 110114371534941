import React from 'react';
import { EditableArea } from '@magnolia/react-editor';
import MetaInfo from 'components/MetaInfo';

function Home(props) {
  const { hero, main } = props;

  return (
    <div className='Home'>
      <MetaInfo props={props} />

      {hero && (
        <div className='Hero'>
          <EditableArea content={hero} />
        </div>
      )}
      {main && <EditableArea content={main} />}
    </div>
  );
}

export default Home;
