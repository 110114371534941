import React from 'react';
import { EditableArea } from '@magnolia/react-editor';
import Product from 'components/Product';

import Settings from 'utils/Settings';
import { GetProductsByFilter } from 'utils/commerce';

class Category extends React.Component {
  state = {};

  sort = (e) => {
    const { type } = e.currentTarget.dataset;
    const newSort = {
      sortType: type,
      sortProp: undefined,
      sortReverse: undefined,
    };

    if (type === '0') {
      newSort.sortProp = 'name';
    } else if (type === '1') {
      newSort.sortProp = 'name';
      newSort.sortReverse = true;
    } else if (type === '2') {
      newSort.sortProp = 'price';
    } else if (type === '3') {
      newSort.sortProp = 'price';
      newSort.sortReverse = true;
    }

    this.setState(newSort);
  };

  async componentDidMount() {
    const products = await GetProductsByFilter(this.props.categoryId, this.props.filter);
    this.setState({ products });
  }

  render() {
    const { hero, main } = this.props;
    const { sortType, sortProp, sortReverse, products = [] } = this.state;
    const translations = Settings.getItem('translations');
    let sortedProducts = [...products];

    if (sortProp) {
      sortedProducts = sortedProducts.sort((a, b) =>
        a[sortProp].localeCompare(b[sortProp], undefined, { numeric: true })
      );
    }

    if (sortReverse) {
      sortedProducts = sortedProducts.reverse();
    }

    return (
      <div className='Category'>
        {hero && <EditableArea content={hero} />}
        <div className='Sort'>
          <span className='title'>{translations.sortBy}</span>
          {[translations.nameAsc, translations.nameDesc, translations.priceAsc, translations.priceDesc].map(
            (label, i) => (
              <span
                key={'Sort__type-' + i}
                className='Sort__type'
                onClick={this.sort}
                data-type={i}
                data-active={i === parseInt(sortType)}
              >
                {label}
              </span>
            )
          )}
          {sortProp && (
            <span className='Sort__type Sort__type--reset' onClick={this.sort}>
              {translations.reset}
            </span>
          )}
        </div>
        <div className='wrapper'>
          {sortedProducts.map((product) => (
            <div key={product.id} className='col col--1-4'>
              <Product {...product} />
            </div>
          ))}
        </div>
        {main && <EditableArea content={main} />}
      </div>
    );
  }
}

export default Category;
