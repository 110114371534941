import React, { useEffect } from 'react';
import Settings from 'utils/Settings';
import { getCurrentLanguage } from 'utils/magnolia';

function getValue(key) {
  let value = '';

  if (key === 'lang') value = getCurrentLanguage().toUpperCase();
  if (key === 'curr') value = Settings.getActiveCurrency().toUpperCase();
  if (key === 'prid') {
    const pathname = window.location.pathname;

    if (pathname.includes('/products/')) {
      value = pathname.replace(/.*\/products\//, '');
    }
  }

  return value;
}

function FreshRelevance(props) {
  const { slot } = props;
  let attrs = { className: 'tmspslot', 'data-k': 'ay70i3c', 'data-p': '1' };
  let parsedSlot;

  useEffect(() => {
    if (window.$TB && window.$TB.hooks) window.$TB.hooks.fireEvent('pageChange', null, { sendBeacon: true });
  }, [props.slot]);

  try {
    parsedSlot = JSON.parse(slot);
  } catch (error) {
    console.error('Can not parse slot');

    return null;
  }

  attrs['data-slid'] = parsedSlot.slid;

  attrs = parsedSlot.merge?.reduce((acc, key) => {
    const value = getValue(key);

    if (value) acc['data-merge-' + key] = value;

    return acc;
  }, attrs);

  return (
    <div>
      <div {...attrs} />
    </div>
  );
}

export default FreshRelevance;
