import React from 'react';
import { Link } from 'react-router-dom';

import { get, getImageUrl } from 'utils/magnolia';
import url from 'utils/url';

class Stories extends React.Component {
  state = {};

  async componentDidMount() {
    const res = await get('/.rest/stories?limit=100&orderBy=created%20desc');
    let stories = res?.results;

    if (!stories) return;

    stories = stories.filter((item) => !item['@path'].startsWith('/insurance-demo/'));

    const isMainIndex = stories.findIndex((item) => item.isMain === true);

    if (isMainIndex > -1) {
      let mainItem = stories[isMainIndex];

      stories.splice(isMainIndex, 1);
      stories.splice(0, 0, mainItem);
    }

    this.setState({ stories });
  }

  renderStory = (story) => {
    const { imagesource, title, lead, author, created } = story;
    const style = {};
    let croppedLead = '';

    if (lead) croppedLead = lead.length > 75 ? lead.substring(0, 74) + '...' : lead;
    if (imagesource) style.backgroundImage = `url(${getImageUrl(imagesource)})`;

    return (
      <Link key={story['@id']} to={url.getPageLink('/stories' + story['@path'])} className='Stories__story'>
        <div className='Stories__storyMeta'>
          <div className='Stories__storyAuthor'>{author}</div>
          <div className='Stories__storyDate'>
            {created ? new Intl.DateTimeFormat('en-GB').format(new Date(created)) : ''}
          </div>
        </div>
        <div className='Stories__storyImage cover' style={style} />
        <div className='Stories__storyTag'>BLOG</div>
        <div className='Stories__storyTitle'>{title}</div>
        <div className='Stories__storyLead'>{croppedLead}</div>
      </Link>
    );
  };

  render() {
    const { stories = [] } = this.state;

    return (
      <div className='Stories-wrapper'>
        <div className='Stories'>{stories.map((story) => this.renderStory(story))}</div>
      </div>
    );
  }
}

export default Stories;
