import React, { useState, useEffect } from 'react';

import { get, getImageUrl } from 'utils/magnolia';

function News(props) {
  const [newsPiece, setNewsPiece] = useState(null);
  const { newsId } = props.match.params;

  useEffect(() => {
    async function getNewsPiece() {
      const news = await get('/.rest/story/' + newsId);
      setNewsPiece(news);
    }

    getNewsPiece();
  }, [newsId]);

  const renderNewsPiece = (newsPiece) => {
    const { title, lead, imagesource, created, blocks } = newsPiece;
    const style = {};

    if (imagesource) style.backgroundImage = `url(${getImageUrl(imagesource)})`;

    return (
      <div className='News wrapper'>
        <div className='News__banner cover' style={style}></div>
        <div className='News__info'>
          <div className='Stories__storyTag text-center'>NEWS</div>
          <div className='News__title text-center'>{title}</div>
          <div className='News__subtitle text-center'>{lead}</div>
          <div className='NewsPortal__articleDate text-center'>
            {created ? new Intl.DateTimeFormat('en-GB').format(new Date(created)) : ''}
          </div>
        </div>

        <div className='Story__blocks'>
          {(blocks || newsPiece)['@nodes'].map((nodeName) => {
            const node = (blocks || newsPiece)[nodeName];
            const { text, image, imageCaption } = node;

            return (
              <React.Fragment key={node['@id']}>
                {text && <p className='News__text' dangerouslySetInnerHTML={{ __html: text }} />}
                {image && (
                  <div className='News__imageWrapper'>
                    <div className='News__imageCaption'>{imageCaption}</div>
                    <div className='News__image'>
                      <img src={getImageUrl(image)} alt='' />
                    </div>
                  </div>
                )}
              </React.Fragment>
            );
          })}
        </div>
      </div>
    );
  };

  return newsPiece ? renderNewsPiece(newsPiece) : null;
}

export default News;
