import React from 'react';

import Events from 'utils/Events';

import { get, getImageUrl } from 'utils/magnolia';
import Commenting from 'components/Commenting';

class Story extends React.Component {
  state = {};

  async componentDidMount() {
    const { storyId } = this.props.match.params;

    const story = await get('/.rest/story/' + storyId);

    Events.emit('updateTitle', story.title);
    this.setState({ story });
  }

  renderStory = (story) => {
    const { title, lead, imagesource, author, created = '', blocks } = story;
    const style = {};

    if (imagesource) style.backgroundImage = `url(${getImageUrl(imagesource)})`;

    return (
      <div className='Story'>
        <div className='wrapper'>
          <div className='Story__visual cover' style={style}>
            <div className='Banner__inner wrapper text-center'>
              <div className='Banner__title '>{title}</div>
              <div className='Banner__subtitle'>{lead}</div>
            </div>
          </div>
          <div className='Story__info'>
            <div className='Story__author'>{author}</div>
            <div className='Story__created'>
              {created ? new Intl.DateTimeFormat('en-GB').format(new Date(created)) : ''}
            </div>
          </div>
          <div className='Story__blocks'>
            {(blocks || story)['@nodes'].map((nodeName) => {
              const node = (blocks || story)[nodeName];
              const { text, image } = node;

              return (
                <React.Fragment key={node['@id']}>
                  {text && <div className='Story__text' dangerouslySetInnerHTML={{ __html: text }} />}
                  {image && (
                    <div className='Story__image'>
                      <img src={getImageUrl(image)} alt='' />
                    </div>
                  )}
                </React.Fragment>
              );
            })}
          </div>
          <div>
            <Commenting props={story['@id']}></Commenting>
          </div>
        </div>
      </div>
    );
  };

  render() {
    const { story } = this.state;

    return story ? this.renderStory(story) : null;
  }
}

export default Story;
