import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import url from 'utils/url';
import { get, getImageUrl } from 'utils/magnolia';
import Btn from 'components/Btn';

function NewsPortal(props) {
  const { metadata, maxElements, sorting, tagsFilterMode } = props;
  const [news, setNews] = useState([]);

  const inClause = '%5Bin%5D'; // Equals [in]
  const pipe = '%7C'; // Equals |

  useEffect(() => {
    async function getNews() {
      const tags = metadata['mgnl:tags'];

      let tagsFilter = tags[0] ? 'mgnl:tags=' + tags[0] : null;

      if (tags.length > 1) {
        // OR MODE
        if (tagsFilterMode && tagsFilterMode === 'or') {
          tagsFilter = 'mgnl:tags' + inClause + '=' + tags[0];

          for (let index = 1; index < tags.length; index++) {
            tagsFilter = tagsFilter + pipe + tags[index];
          }
        } else {
          // AND MODE
          for (let index = 1; index < tags.length; index++) {
            tagsFilter = tagsFilter + '&mgnl:tags=' + tags[index];
          }
        }
      }

      const orderFilter = sorting ? 'orderBy=created%20' + sorting : null;
      const maxElementFilter = maxElements ? 'limit=' + maxElements : null;

      let filterString = '';

      if (tagsFilter != null) {
        if (!filterString) filterString = '?';
        filterString += tagsFilter;
      }

      if (orderFilter) {
        if (!filterString) filterString = '?';
        else filterString += '&';
        filterString += orderFilter;
      }

      if (maxElementFilter) {
        if (!filterString) filterString = '?';
        else filterString += '&';
        filterString += maxElementFilter;
      }

      const fetchedNews = await get('/.rest/stories' + filterString);

      setNews(fetchedNews.results);
    }
    getNews();
  }, [metadata, maxElements, sorting, tagsFilterMode]);

  const renderFirstArticle = (article) => {
    const { imagesource, title, lead, created } = article;
    const style = {};
    let croppedLead = '';

    if (lead) croppedLead = lead.length > 75 ? lead.substring(0, 74) + '...' : lead;
    if (imagesource) style.backgroundImage = `url(${getImageUrl(imagesource)})`;

    return (
      <div key={article['@id']} className='NewsPortal__firstArticle cover' style={style}>
        <div className='NewsPortal__articleInner wrapper text-center'>
          <div className='NewsPortal__articleDate'>
            {created ? new Intl.DateTimeFormat('en-GB').format(new Date(created)) : ''}
          </div>
          <div className='NewsPortal__articleTitle'>{title}</div>
          <div className='NewsPortal__articleLead'>{croppedLead}</div>
          <Btn text='Read article' link={url.getPageLink('/news' + article['@path'])} />
        </div>
      </div>
    );
  };

  const renderNews = (article) => {
    const { imagesource, title, lead, created } = article;
    const style = {};
    let croppedLead = '';

    if (lead) croppedLead = lead.length > 75 ? lead.substring(0, 74) + '...' : lead;
    if (imagesource) style.backgroundImage = `url(${getImageUrl(imagesource)})`;

    return (
      <Link key={article['@id']} to={url.getPageLink('/news' + article['@path'])} className='NewsPortal__article'>
        <div className='NewsPortal__articleImage cover' style={style} />
        <div className='Stories__storyTag'>NEWS</div>
        <div className='NewsPortal__articleTitle'>{title}</div>
        <div className='NewsPortal__articleLead'>{croppedLead}</div>
        <div>
          <div className='NewsPortal__articleDate'>
            {created ? new Intl.DateTimeFormat('en-GB').format(new Date(created)) : ''}
          </div>
        </div>
      </Link>
    );
  };

  const firstArticle = news?.shift() || null;

  return firstArticle ? (
    <div className='NewsPortal'>
      <div>{renderFirstArticle(firstArticle)}</div>
      <div className='NewsPortal__wrapper'>{news.map((article) => renderNews(article))}</div>
    </div>
  ) : null;
}

export default NewsPortal;
