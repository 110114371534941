import React from 'react';

import { getImageUrl } from 'utils/magnolia';

function Image(props) {
  const { image, imageChooser } = props;
  let actualImage;

  if (imageChooser) {
    if (imageChooser.field === 'internal') {
      actualImage = imageChooser.image;
    } else {
      actualImage = imageChooser.cloudinaryImage;
    }
  } else {
    actualImage = image;
  }

  return actualImage ? <img className='Image' src={getImageUrl(actualImage)} alt={actualImage['@name']} /> : null;
}

export default Image;
