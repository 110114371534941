import React, { useRef } from 'react';
import { EditableArea, EditableComponent } from '@magnolia/react-editor';
import Settings from 'utils/Settings';

function Carousel(props) {
  const carouselEle = useRef(null);
  const { items, timeInterval = 3 } = props;
  const numberOfItems = items['@nodes'].length;

  if (!Settings.getItem('magnoliaContext')?.isMagnoliaEdit) {
    setInterval(() => {
      if (carouselEle?.current) {
        const width = window.innerWidth - 20;
        const currentScrollLeft = carouselEle.current.scrollLeft;
        let newScrollLeft = currentScrollLeft + width;

        if (currentScrollLeft > (numberOfItems - 1) * width) newScrollLeft = 0;

        carouselEle.current.scrollLeft = newScrollLeft;
      }
    }, timeInterval * 1000);
  }

  function customView({ content }) {
    let className = 'Carousel';

    if (numberOfItems > 1) className += ' Carousel--slider';

    return (
      <div ref={carouselEle} className={className}>
        {content['@nodes'].map((nodeName) => (
          <div key={content[nodeName]['@id']} className='Carousel__item'>
            <EditableComponent content={content[nodeName]} />
          </div>
        ))}
      </div>
    );
  }

  return <EditableArea content={items} customView={customView} />;
}

export default Carousel;
