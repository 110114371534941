import React from 'react';
import { NavLink } from 'react-router-dom';
import url from 'utils/url';

function NodeLink({ node, onClickFn = () => {}, isCategory }) {
  let isExternal = false;
  let linkTarget = '';

  if (node.linkType?.link?.field === 'external' || node.name.match(/^https?:\/\//)) {
    isExternal = true;
    linkTarget = node.linkType?.link?.external || node.name;
  } else {
    linkTarget = url.getPageLink(node.linkType?.link?.internal) || node.key || node.name;
    linkTarget = (linkTarget.match(/^\//) ? '' : '/') + linkTarget;
  }

  const label = node.label || node.name;

  return isExternal ? (
    <a key={node['@id']} href={linkTarget} target='_blank' rel='noopener noreferrer' onClick={onClickFn}>
      {label}
    </a>
  ) : (
    <NavLink
      key={node['@id']}
      to={(isCategory ? '/categories' : '') + linkTarget}
      onClick={onClickFn}
      style={{ textDecoration: 'none' }}
    >
      {label}
    </NavLink>
  );
}

export default NodeLink;
