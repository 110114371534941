import React from 'react';
import { Link } from 'react-router-dom';
import { get, getImageUrl } from 'utils/magnolia';
import url from 'utils/url';

class Contact extends React.Component {
  state = { contact: {} };
  link = '/contacts';
  async componentDidMount() {
    this.link += this.props.contactId;
    const contact = await get('/.rest/delivery/contact/' + this.props.contactId, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (contact !== {}) this.setState({ contact: contact });
  }

  render() {
    const { contact } = this.state;
    const { name, email, title, photo } = contact;

    return (
      <div className='ContactComponent'>
        <div className='ContactComponent__info'>
          <img className='ContactComponent__img' src={getImageUrl(photo)} alt={name}></img>
          <div className='ContactComponent__name'>{name}</div>
          <div className='ContactComponent__title'>{title}</div>
          <div className='ContactComponent__actions'>
            <Link className='Btn Btn--3' to={url.getPageLink(this.link) || '#'}>
              View Profile
            </Link>
            <a className='Btn Btn--2' href={'mailto:' + email}>
              Contact
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default Contact;
