import React from 'react';
import { EditableArea } from '@magnolia/react-editor';

import Card from 'components/Card';

function Inspiration(props) {
  const { heroarea, inspiration } = props;

  return (
    <div className='Inspiration'>
      {heroarea && (
        <div className='Hero'>
          <EditableArea content={heroarea} />
        </div>
      )}
      {inspiration && (
        <div className='Inspiration__list wrapper'>
          {inspiration['@nodes']
            .map((nodeName) => inspiration[nodeName])
            .map((node) => (
              <Card
                key={node['@id']}
                title={node['name']}
                link={'/inspirations' + node['@path']}
                image={node['hero']}
              />
            ))}
        </div>
      )}
    </div>
  );
}

export default Inspiration;
