import React from 'react';
import { Link } from 'react-router-dom';

import url from 'utils/url';

import { ReactComponent as ArrowDark } from 'img/arrow-dark.svg';
import { ReactComponent as ArrowWhite } from 'img/arrow-white.svg';

function Btn(props) {
  const { text, link, isSecondaryStyle } = props;
  let className = 'Btn';

  if (isSecondaryStyle) className += ' Btn--2';

  return text ? (
    <Link className={className} to={url.getPageLink(link) || '#'}>
      {text}
      <ArrowDark className='Btn__arrow Btn__arrow--dark' />
      <ArrowWhite className='Btn__arrow Btn__arrow--white' />
    </Link>
  ) : null;
}

export default Btn;
