import React from 'react';
import Events from 'utils/Events';
import { get, getImageUrl } from 'utils/magnolia';

class Event extends React.Component {
  state = {};

  async componentDidMount() {
    const { eventId } = this.props.match.params;

    const event = await get('/.rest/events/' + eventId);

    Events.emit('updateTitle', event.name);
    this.setState({ event });
  }

  renderEvent = (event) => {
    const { name, abstract, description, location, image, startDate = '' } = event;
    const style = {};

    if (image) style.backgroundImage = `url(${getImageUrl(image)})`;

    return (
      <div className='Story'>
        <div className='wrapper'>
          <div className='Story__visual cover' style={style}>
            <div className='Banner__inner wrapper text-center'>
              <div className='Banner__title '>{name}</div>
              <div className='Banner__subtitle'>{abstract}</div>
            </div>
          </div>
          <div className='Story__split'>
            <div>
              <div className='Story__splitLabel'>TIME</div>
              <div>{startDate ? new Intl.DateTimeFormat('en-GB').format(new Date(startDate)) : ''}</div>
              <div className='Story__splitLabel'>LOCATION</div>
              <div>{location}</div>
            </div>
            <div>
              <div className='Story__splitRichText' dangerouslySetInnerHTML={{ __html: description }}></div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    const { event } = this.state;

    return event ? this.renderEvent(event) : null;
  }
}

export default Event;
