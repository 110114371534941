import React, { useRef } from 'react';

import { getImageUrl } from 'utils/magnolia';
import BackgroundImage from 'utils/BackgroundImage';
import { Link } from 'react-router-dom';
import url from 'utils/url';
import { ReactComponent as ArrowDark } from 'img/arrow-dark.svg';
import { ReactComponent as ArrowWhite } from 'img/arrow-white.svg';

function BannerContainer({image, useImageSize, children, ...props}) {
  let {className, style} = props;
  let imageUrl = getImageUrl(image);
  let isVideo = false;

  if (useImageSize) {
    style.height = 'auto';
  } else {
    className += ' cover';
  }
  
  if (image?.metadata?.format?.indexOf('video') > -1) {
    isVideo = true;
  }

  if (isVideo) {
    return (
      <div className={className} style={style}>
        <video src={imageUrl} muted autoPlay loop />
        {children}
      </div>
    )
  }
      
  if (imageUrl && useImageSize) {
    return (
      <div className='text-center'>
        <img src={imageUrl} alt='Banner graphics' style={{ objectPosition: `${image.focal?.point?.left}% ${image.focal?.point?.top}%` }}/>
        {children}
      </div>
    );
  }

  return (
    <BackgroundImage className={className} image={image} data-mt>
      {children}
    </BackgroundImage>
  );
}

function Banner(props) {
  const ctaEle = useRef(null);
  const { isLarge, title, subtitle, textColor, image, useImageSize, cta, scoreModels = [] } = props;
  const style = {};
  let className = 'Banner';

  if (isLarge === 'true') className += ' Banner--large';
  if (textColor) style.color = textColor;

  return (
    <div>
      <BannerContainer className={className} style={style} image={image} useImageSize={useImageSize}>
        <div className='Banner__inner wrapper text-center'>
          {title && <div className='Banner__title'>{title}</div>}
          {subtitle && <div className='Banner__subtitle'>{subtitle}</div>}
          {cta?.text && (
            <Link
              ref={ctaEle}
              className='Btn'
              to={url.getPageLink(cta?.link) || '#'}
              data-score-model={window.scoreModel.prepareDataScoreModels(scoreModels)}
              onClick={() => {
                window.scoreModel.performPageEventScoring(ctaEle.current);
              }}
            >
              {cta?.text}
              <ArrowDark className='Btn__arrow Btn__arrow--dark' />
              <ArrowWhite className='Btn__arrow Btn__arrow--white' />
            </Link>
          )}
        </div>
      </BannerContainer>
    </div>
  )
  
}

export default Banner;
