import React from 'react';

function Title(props) {
  const { title, isSecondaryStyle } = props;
  let className = 'title';

  if (isSecondaryStyle) className += ' title--2';

  return <div className={className}>{title}</div>;
}

export default Title;
