import { EditorContextHelper } from '@magnolia/react-editor';
import Settings from 'utils/Settings';

export function getImageUrl(image) {
  const baseUrl = Settings.getItem('baseUrl');
  let imageLink = '#';
  if (!image) return imageLink;
  if (image['@link']) {
    if (image['@link'].indexOf('http') > -1) {
      return image['@link'];
    } else {
      imageLink = baseUrl + image['@link'];
    }
  } else {
    imageLink = baseUrl + '/dam/' + image;
  }

  return imageLink;
}

export async function get(url, data) {
  const baseUrl = Settings.getItem('baseUrl');
  let fullUrl = url;
  const languageCode = getCurrentLanguage();

  if (languageCode) {
    if (url.indexOf('?') < 0) {
      fullUrl += '?';
    } else {
      fullUrl += '&';
    }

    fullUrl += 'lang=' + languageCode.replace('_', '-');
  }

  const response = await fetch(baseUrl + fullUrl, data);
  const json = await response.json();

  if (json.error) return false;

  return json;
}

export function getCurrentLanguage() {
  const languages = Settings.getItem('languages');

  for (let i = 0; i < languages.length; i++) {
    const language = languages[i];

    if (new RegExp('/' + language + '($|/)').test(window.location.pathname)) {
      return language;
    }
  }

  return languages[0];
}

function removeCurrentLanguage(string, currentLanguage) {
  return string.replace(new RegExp('/' + currentLanguage + '($|/)'), '/');
}

export function changeLanguage(newLanguage) {
  const nodeName = Settings.getItem('nodeName');
  const languages = Settings.getItem('languages');
  let pathname = window.location.pathname;
  const currentLanguage = getCurrentLanguage();

  pathname = removeCurrentLanguage(pathname, currentLanguage);

  if (languages[0] !== newLanguage) {
    if (pathname.indexOf(nodeName) > -1) {
      pathname = pathname.replace(new RegExp(nodeName), '/' + newLanguage + nodeName);
    } else {
      pathname = '/' + newLanguage + pathname;
    }
  }

  window.location.href = window.location.origin + pathname + window.location.search;
}

export function getRouterBasename() {
  const nodeName = Settings.getItem('nodeName');
  const languages = Settings.getItem('languages');
  const pathname = window.location.pathname;
  const apps = ['inspirations-app'];

  if (apps) {
    apps.forEach(function (app) {
      if (pathname.indexOf(app) > -1) {
        return pathname.replace(new RegExp(app + '.*'), '') + app;
      }
    });
  }

  if (pathname.indexOf(nodeName) > -1) {
    return pathname.replace(new RegExp(nodeName + '.*'), '') + nodeName;
  }

  const currentLanguage = getCurrentLanguage();

  return languages[0] === currentLanguage ? '/' : '/' + currentLanguage;
}

export async function getPage() {
  const href = window.location.href;
  let shouldRemoveRootNodePath;

  // Pages App
  let deliveryApi = '/.rest/delivery/pages';
  let templateAnnotationsApi = '/.rest/template-annotations/v1/website';
  let rootNodePath = Settings.getItem('nodeName');

  // Campaign Manager
  if (href.includes('/campaign/')) {
    deliveryApi = '/.rest/campaign-manager';
    templateAnnotationsApi = '/.rest/template-annotations/v1/campaign-manager';
    rootNodePath = '/campaign';
    shouldRemoveRootNodePath = true;
  }
  // Mobile App
  else if (href.includes('/mobile-page/')) {
    deliveryApi = '/.rest/mobile-pages';
    templateAnnotationsApi = '/.rest/template-annotations/v1/mobile-pages';
    rootNodePath = '/mobile-page';
    shouldRemoveRootNodePath = true;
  }
  // Customer Journey
  else if (href.includes('/customer-journey/')) {
    deliveryApi = '/.rest/customer-journey';
    templateAnnotationsApi = '/.rest/template-annotations/v1/customer-journeys';
    rootNodePath = '/customer-journey';
    shouldRemoveRootNodePath = true;
  }

  const magnoliaContext = EditorContextHelper.getMagnoliaContext(href, rootNodePath, Settings.getItem('languages'));
  const { isMagnolia, version, nodePath, search } = magnoliaContext;
  let pagePath = nodePath;

  Settings.setItem('magnoliaContext', magnoliaContext);

  // {path} for Campaign Manager/Mobile App/Customer Journey does not include rootNodePath
  if (shouldRemoveRootNodePath) pagePath = pagePath.replace(rootNodePath, '');

  // If on a product detail page fetch products page template
  if (/\/products\/.+/.test(pagePath)) pagePath = pagePath.split('/products/')[0] + '/products';

  let params = search;

  // p13n for Segment
  if (!isMagnolia) {
    try {
      const cookieValue = document.cookie.replace(/(?:(?:^|.*;\s*)ajs_anonymous_id\s*=\s*([^;]*).*$)|^.*$/, '$1');
      const traitsResponse = await get('/.rest/segment-traits', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: `{"id":"anonymous_id:${cookieValue}"}`,
      });

      const mostViewedCategory = traitsResponse?.traits?.most_viewed_category;
      const lastViewedCategory = traitsResponse?.traits?.last_viewed_category;

      if (mostViewedCategory) params += '&segmentMostViewedCategory=' + mostViewedCategory;
      if (lastViewedCategory) params += '&segmentLastViewedCategory=' + lastViewedCategory;
    } catch (e) {
      console.error(e);
    }
  }

  const content = await get(deliveryApi + pagePath + params);
  let templateAnnotations = {};

  if (isMagnolia && !version) {
    templateAnnotations = await get(templateAnnotationsApi + pagePath);
  }

  // Content Recommender module (https://docs.magnolia-cms.com/recommender/index.html)
  window.scoreModel.getPageVisitMetaTags(content.scoreModels);

  return {
    templateAnnotations,
    content,
  };
}
