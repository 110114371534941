import React from 'react';
import { Link } from 'react-router-dom';

import Product from 'components/Product';

import Events from 'utils/Events';
import Commercetools from 'utils/Commercetools';
import { GetProductById } from 'utils/commerce';
import url from 'utils/url';
import { getCurrentLanguage, get, getImageUrl } from 'utils/magnolia';

import { ReactComponent as ArrowWhite } from 'img/arrow-white.svg';
class ProductLink extends React.Component {
  state = {};

  async componentDidMount() {
    const product = await GetProductById(this.props.fullProductId);

    this.setState({ product });
  }

  render() {
    const { product } = this.state;

    return product ? (
      <Link to={url.getProductLink(this.props.fullProductId)} className='InspirationDetail__link'>
        <div className='InspirationDetail__linkName'>{product.name}</div>
        <div className='InspirationDetail__linkInfo'>
          <div>{product.description}</div>
          <div>
            <div className='InspirationDetail__linkPrice'>
              {product.price}
              <ArrowWhite />
            </div>
          </div>
        </div>
      </Link>
    ) : null;
  }
}

class ProductsSearch extends React.Component {
  state = {};

  async componentDidMount() {
    const filter = '&text.' + getCurrentLanguage() + '=' + this.props.searchText;
    const products = await Commercetools.getProductsByFilter(filter);

    this.setState({ products });
  }

  render() {
    const { products } = this.state;

    return products ? products.map((product) => <Product key={product.id} product={product} />) : null;
  }
}

class InspirationDetail extends React.Component {
  state = {};

  async componentDidMount() {
    const { folder, inspirationId } = this.props.match.params;
    const inspiration = await get('/.rest/inspiration/' + folder + '/' + inspirationId.replace(/\.html|\.htm/, ''));

    Events.emit('updateTitle', inspiration.name);
    this.setState({ inspiration });
  }

  renderProductCard = (productCard, size) => {
    const { image, product, text } = productCard;
    const productCardImage = getImageUrl(image);

    return (
      <div key={productCard['@id']}>
        {productCardImage && <img className='InspirationDetail__image' src={productCardImage} alt='' />}
        {product && <ProductLink fullProductId={product} />}
        {text && <p className='InspirationDetail__text'>{text}</p>}
      </div>
    );
  };

  renderInspiration = (inspiration) => {
    const { name, hero, heroProduct, text, productLines, searchText } = inspiration;
    const heroImage = getImageUrl(hero);

    return (
      <div className='InspirationDetail'>
        <div className='wrapper'>
          {name && <div className='InspirationDetail__title'>{name}</div>}
          <div className='InspirationDetail__hero'>
            {heroImage && <img className='InspirationDetail__image' src={heroImage} alt='' />}
            {heroProduct && <ProductLink fullProductId={heroProduct} />}
          </div>
          {text && <p className='InspirationDetail__text'>{text}</p>}
          {productLines &&
            productLines['@nodes']
              .map((nodeName) => productLines[nodeName])
              .map((productLine) => {
                const { productCards } = productLine;

                return (
                  <div key={productLine['@id']} className='InspirationDetail__productLine'>
                    {productCards['@nodes']
                      .map((nodeName) => productCards[nodeName])
                      .map((productCard) => this.renderProductCard(productCard, productCards['@nodes'].length))}
                  </div>
                );
              })}
          {searchText && <ProductsSearch searchText={searchText} />}
        </div>
      </div>
    );
  };

  render() {
    const { inspiration } = this.state;

    return inspiration ? this.renderInspiration(inspiration) : null;
  }
}

export default InspirationDetail;
