import React from 'react';
import Settings from 'utils/Settings';

function Comment(props) {
  const { comment, metadata } = props;

  return Settings.getItem('magnoliaContext')?.isMagnoliaEdit ? (
    <div className='Comment'>
      <div className='Comment__metadata'>
        <div>
          <b>Created by:</b> {metadata['mgnl:createdBy']}
        </div>
        <div>
          <b>Last modified by:</b> {metadata['mgnl:lastModifiedBy']}
        </div>
        <div>
          <b>Last modified:</b>{' '}
          {new Intl.DateTimeFormat('en-GB', { dateStyle: 'short', timeStyle: 'short' }).format(
            new Date(metadata['mgnl:lastModified'])
          )}
        </div>
      </div>
      {comment}
    </div>
  ) : null;
}

export default Comment;
