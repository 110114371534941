import React from 'react';

import Settings from 'utils/Settings';
import { getCurrentLanguage, changeLanguage } from 'utils/magnolia';

import NodeLink from 'components/Link';

function getLink(authorUrl, publicUrl) {
  if (window.location.href.indexOf('/home') > -1) return authorUrl;

  return window.location.href.replace(/-spa(.*?)\./, publicUrl + '.');
}

const renderSettings = (all, active, onClick) =>
  all.map((item) => (
    <span
      key={`Footer__btn-${item}`}
      className='Footer__btn'
      data-active={active === item}
      onClick={() => onClick(item)}
    >
      {item}
    </span>
  ));

function Footer(props) {
  const groups = props.navigation['@nodes'].map((nodeName) => props.navigation[nodeName]);
  const brandName = Settings.getItem('brandName');
  const currencies = Settings.getItem('currencies').split(' ');
  const languages = Settings.getItem('languages');
  const year = new Date().getFullYear();
  const cols = groups.length + 1;

  return (
    <footer className='Footer'>
      <div className='wrapper text-center'>
        <div className={`col col--1-${cols}`}>
          {currencies && (
            <div>
              <div>{Settings.getItem('translations').currencies}</div>
              {renderSettings(currencies, Settings.getActiveCurrency(), Settings.setActiveCurrency)}
            </div>
          )}
          {languages && (
            <div>
              <div>{Settings.getItem('translations').languages}</div>
              {renderSettings(languages, getCurrentLanguage(), changeLanguage)}
            </div>
          )}
          <div className='Footer__flags'>
            <div>{Settings.getItem('translations').countrySite}</div>
            <a href={getLink('/home', '-spa')}>
              <span role='img' aria-label='en'>
                🇬🇧
              </span>
            </a>
            <a href={getLink('/home_ch', '-spa-ch')}>
              <span role='img' aria-label='ch'>
                🇨🇭
              </span>
            </a>
            <a href={getLink('/home_de', '-spa-de')}>
              <span role='img' aria-label='de'>
                🇩🇪
              </span>
            </a>
            <a href={getLink('/home_es', '-spa-es')}>
              <span role='img' aria-label='es'>
                🇪🇸
              </span>
            </a>

            <a href={getLink('/home_us', '-spa-en-us')}>
              <span role='img' aria-label='us'>
                🇺🇸
              </span>
            </a>
            <a href={getLink('/home_cn', '-spa-zh-cn')}>
              <span role='img' aria-label='cn'>
                🇨🇳
              </span>
            </a>
          </div>
        </div>
        {groups.map((group) => (
          <div key={group['@id']} className={`col col--1-${cols}`}>
            {group['@nodes'].map((nodeName) => {
              const node = group[nodeName];

              return <NodeLink key={node['@id']} node={node} />;
            })}
          </div>
        ))}
        <div className='Footer__copy'>
          © {year} {brandName}
        </div>
      </div>
    </footer>
  );
}

export default Footer;
