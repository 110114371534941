import React from 'react';
import { EditableArea } from '@magnolia/react-editor';
import Product from 'pages/Product';
import Settings from 'utils/Settings';

function Products(props) {
  const { hero, upselling } = props;

  return (
    <div className='Products'>
      <EditableArea content={hero} />
      {Settings.getItem('magnoliaContext')?.isMagnoliaEdit ? (
        <div className='Section text-center'>
          <h2>This is a base template for product page</h2>
          <p>Product will be rendered in this area</p>
        </div>
      ) : (
        <Product />
      )}
      <EditableArea content={upselling} />
    </div>
  );
}

export default Products;
