import 'mapbox-gl/dist/mapbox-gl.css';
import 'index.css';

import React, { useEffect } from 'react';
import { Route, BrowserRouter as Router, Switch, useLocation } from 'react-router-dom';
import { get, getRouterBasename } from 'utils/magnolia';

import Categories from 'pages/Categories';
import Commercetools from 'utils/Commercetools';
import ContactDetail from 'pages/Contact';
import Event from 'pages/Event';
import Events from 'utils/Events';
import Footer from 'components/Footer';
import Header from 'components/Header';
import InspirationDetail from 'pages/InspirationDetail';
import News from 'pages/News';
import PageLoader from 'utils/PageLoader';
import ReactDOM from 'react-dom';
import Settings from 'utils/Settings';
import Story from 'pages/Story';
import perfume from 'utils/perfume';

perfume();

const hostname = window.location.hostname;
const href = window.location.href;
console.e = console.error;

function getBaseUrl() {
  let baseUrl;

  const magnoliaInstance = process.env.REACT_APP_MGNL_INSTANCE;

  if (magnoliaInstance) {
    baseUrl = magnoliaInstance;
  } else {
    baseUrl = 'https://' + hostname.replace(/-spa(.*?)\./, '.');
  }

  return baseUrl;
}

fetch(getBaseUrl() + '/.rest/nodes/v1/marketing-tags/googleanalytics/')
  .then((response) => response.json())
  .then((json) => (document.getElementsByTagName('head')[0].innerHTML += json.properties[0].values[0]))
  .catch((e) => {
    console.e(e);
  });

let nodeName = '/home';
let languages = ['en', 'de', 'es', 'fr', 'it', 'en_US', 'zh_CN'];

if (hostname.includes('-spa-ch') || href.includes('/home_ch')) {
  nodeName = '/home_ch';
  languages = ['de', 'it', 'fr', 'en'];
}

if (hostname.includes('-spa-de') || href.includes('/home_de')) {
  nodeName = '/home_de';
  languages = ['de', 'en'];
}

if (hostname.includes('-spa-es') || href.includes('/home_es')) {
  nodeName = '/home_es';
  languages = ['es', 'en'];
}

if (hostname.includes('-spa-en-us') || href.includes('/home_us')) {
  nodeName = '/home_us';
  languages = ['en_US', 'en'];
}

if (hostname.includes('-spa-zh-cn') || href.includes('/home_cn')) {
  nodeName = '/home_cn';
  languages = ['zh_CN', 'en'];
}

Settings.setItem('baseUrl', getBaseUrl());
Settings.setItem('nodeName', nodeName);
Settings.setItem('languages', languages);

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

const init = async () => {
  const rootEle = document.getElementById('root');
  const brand = await get('/.rest/brands' + nodeName);
  const navigation = await get('/.rest/navigations' + nodeName);
  const i18n = await get('/.rest/delivery/i18n/home');

  Object.keys(brand).forEach((key) => Settings.setItem(key, brand[key]));
  Settings.setItem(
    'translations',
    i18n['@nodes'].reduce((acc, item) => {
      acc[item] = i18n[item].value;
      return acc;
    }, {})
  );
  Events.on('updateTitle', (title = '') => (document.title = (title ? title + ' | ' : '') + brand.brandName));
  Events.emit('updateTitle');
  await Commercetools.init();

  if (window.location.search.includes('mgnlPreview=false')) {
    rootEle.classList.add('disable-a-pointer-events');
  }

  ReactDOM.render(
    <Router basename={getRouterBasename()}>
      <ScrollToTop />
      <Header navigation={navigation.header} />
      <Switch>
        <Route path='/inspirations(-app)?/:folder/:inspirationId' component={InspirationDetail} />
        <Route path={['/stories/variants/', '/events/variants/']} component={PageLoader} />
        <Route path='/stories/:storyId*' component={Story} />
        <Route path='/events/:eventId' component={Event} />
        <Route path='/contacts/:contactId' component={ContactDetail} />
        <Route path='/news/:newsId' component={News} />
        <Route
          path='/categories/:categoryKey'
          render={(props) => <Categories key={props.match.params.categoryKey} {...props} />}
        />
        <Route path='/' component={PageLoader} />
      </Switch>
      <Footer navigation={navigation.footer} />
    </Router>,
    rootEle
  );
};

init();
