import React, { useState, useEffect } from 'react';
import url from 'utils/url';
import { get } from 'utils/magnolia';

function MetaInfo(props) {
  const pageProps = props.props;
  const meta_title = pageProps.title;
  const meta_description = pageProps.description;
  const meta_keywords = pageProps.keywords;
  const meta_noIndex = pageProps.noIndex;
  const meta_canonical = pageProps.canonical;

  const [canonical, setCanonical] = useState({});

  const savedVisibility = localStorage.getItem('divVisibility');
  const [divVisible, setDivVisible] = useState(savedVisibility === 'true');

  useEffect(() => {
    localStorage.setItem('divVisibility', divVisible);
  }, [divVisible]);

  const toggleDivVisibility = () => {
    setDivVisible((prevVisibility) => !prevVisibility);
  };

  function checkMetaTitle() {
    if (meta_title && meta_title.length > 0) {
      if (meta_title.length > 60) {
        return (
          <span
            className='fa fa-sort-amount-asc text-orange'
            data-toggle='tooltip'
            title='Title should not be longer then 70 characters.'
          ></span>
        );
      } else {
        return <span className='fa fa-check'></span>;
      }
    } else {
      return <span className='fa fa-exclamation-triangle text-red'></span>;
    }
  }

  function checkMetaDescription() {
    if (meta_description && meta_description.length > 0) {
      if (meta_description.length > 160) {
        return (
          <span
            className='fa fa-sort-amount-asc text-orange'
            data-toggle='tooltip'
            title='Description should not be longer then 160 characters.'
          ></span>
        );
      } else {
        return <span className='fa fa-check'></span>;
      }
    } else {
      return <span className='fa fa-exclamation-triangle text-red'></span>;
    }
  }

  function checkMetaKeywords() {
    if (meta_keywords && meta_keywords.length > 0) {
      if (meta_keywords.split(',') > 10) {
        return (
          <span
            className='fa fa-sort-amount-asc text-orange'
            data-toggle='tooltip'
            title='Keywords should not contain more then 10 keyword phrases.'
          ></span>
        );
      } else {
        return <span className='fa fa-check'></span>;
      }
    } else {
      return <span className='fa fa-exclamation-triangle text-red'></span>;
    }
  }

  function checkMetaRobots() {
    if (meta_noIndex === false) {
      return (
        <div>
          <span className='fa fa-bookmark robots'></span>
          <label>robots: </label>index, follow
        </div>
      );
    } else {
      return (
        <div>
          <span className='fa  fa-bookmark-o robots'></span>
          <label>robots: </label>noindex, nofollow
        </div>
      );
    }
  }

  function checkMetaCanonical() {
    if (meta_canonical) {
      let href = url.getCanonicalHref(meta_canonical);

      if (canonical?.results?.['0']['@path']) {
        href = canonical?.results?.['0']['@path'];
      }
      return (
        <div className='canonical'>
          <span className='fa fa-link'></span>
          <label className='canonicalLabel'>Canonical: </label>
          <ul>
            <li>
              <label>type:</label>
              {meta_canonical.field}
            </li>
            <li>
              {' '}
              <label> href:</label> {href}{' '}
            </li>
          </ul>
        </div>
      );
    } else {
      return <span className='fa fa-exclamation-triangle text-red'></span>;
    }
  }

  useEffect(() => {
    let canonical_value;
    let canonicalURLCall;
    if (meta_canonical) {
      canonical_value = url.getCanonicalHref(meta_canonical);
      if (meta_canonical.field === 'internal') {
        canonicalURLCall = '/.rest/delivery/pages/?@jcr:uuid=' + canonical_value;
      } else if (meta_canonical.field === 'story') {
        canonicalURLCall = '/.rest/stories/?@jcr:uuid=' + canonical_value;
      } else if (meta_canonical.field === 'event') {
        canonicalURLCall = '/.rest/events/?@jcr:uuid=' + canonical_value;
      }
    }

    async function getCanonical() {
      if (canonicalURLCall) {
        const canonical = await get(canonicalURLCall);
        setCanonical(canonical);
      }
    }

    getCanonical();
  }, [meta_canonical]);

  let params = window.location.search;
  const urlParams = new URLSearchParams(params);
  const mgnlPreview = urlParams.get('mgnlPreview');
  if (window.location.search.indexOf('mgnlPreview') < 0 || mgnlPreview === 'true') {
    return '';
  }

  return (<div>
      <div className='Meta-Info-Hide-Button-Container'>
        <button className='Meta-Info-Hide-Button' onClick={toggleDivVisibility}>
          <svg xmlns="http://www.w3.org/2000/svg" height="1em"
               viewBox="0 0 512 512">
            <path
              d="M480 32c0-12.9-7.8-24.6-19.8-29.6s-25.7-2.2-34.9 6.9L381.7 53c-48 48-113.1 75-181 75H192 160 64c-35.3 0-64 28.7-64 64v96c0 35.3 28.7 64 64 64l0 128c0 17.7 14.3 32 32 32h64c17.7 0 32-14.3 32-32V352l8.7 0c67.9 0 133 27 181 75l43.6 43.6c9.2 9.2 22.9 11.9 34.9 6.9s19.8-16.6 19.8-29.6V300.4c18.6-8.8 32-32.5 32-60.4s-13.4-51.6-32-60.4V32zm-64 76.7V240 371.3C357.2 317.8 280.5 288 200.7 288H192V192h8.7c79.8 0 156.5-29.8 215.3-83.3z"/>
          </svg>
        </button>
      </div>
      {divVisible && <div id='Meta-Container' className='Meta-Info-Outer-Container'>
        <div className='Meta-Info-Inner-Container'>
          <div className='' id='metaInfo'>
          <span className='close' onClick={toggleDivVisibility}>
            <i className='fa fa-times'></i>
          </span>
            <h3 className=''>Metadata check for SEO:</h3>
            <div>
              {checkMetaTitle()}
              <label>title: </label>
              {meta_title}
            </div>
            <div>
              {checkMetaDescription()}
              <label>description: </label>
              {meta_description}
            </div>
            <div>
              {checkMetaKeywords()}
              <label>keywords: </label>
              {meta_keywords}
            </div>
            {checkMetaRobots()}
            {checkMetaCanonical()}
          </div>
          <div className='text-secondary'>
          <span className='font-italic'>
            * Don't worry, this info box displays only on author instance (both edit or preview mode). But never on
            public.
          </span>
          </div>
        </div>
      </div>}
    </div>

  );
}

export default MetaInfo;
