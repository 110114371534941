import React from "react";
import { EditableArea } from "@magnolia/react-editor";

function Mobile(props) {
  const { main } = props;

  return <div className="Mobile">{main && <EditableArea content={main} />}</div>;
}

export default Mobile;
